import * as React from "react"
import Layout from "../components/layout"
import Introduction from "../components/introduction"
import TextBanner from "../components/textBanner"
import ImageText from "../components/imageText"
import Seo from "../components/seo"
import { graphql } from "gatsby"


const ReferralsPage = ({ data }) => {
  const seoInfo = data.datoCmsReferralsPage
  return (
    <Layout>
      <Seo description={seoInfo.metaDescription} title={seoInfo.metaTitle} />

      <Introduction title="Refer With Confidence" subtitle={data.datoCmsReferralsPage.referWithConfidence} />

      <ImageText alt="Referrals Image" background="#ECECEC" image={data.image.childImageSharp.gatsbyImageData} text={data.datoCmsReferralsPage.sectionTopText} second={data.datoCmsReferralsPage.sectionBottomText}/>

      <TextBanner button="Contact Us" link="/contact" background="#E1E3DC" title="Become a Referral Partner" text={data.datoCmsReferralsPage.becomeAReferralPartner} />
    </Layout>
  )
}
export const query = graphql`
  query {
    datoCmsReferralsPage {
    referWithConfidence
    sectionBottomText
    sectionTopText
    becomeAReferralPartner
    metaDescription
    metaTitle

  }
    image: file(relativePath: { eq: "referrals.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default ReferralsPage
