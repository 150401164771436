import React from 'react'
import { Link } from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image'

const ImageText = ({link, alt, bottomIcon, text, second, button, title, image, caption, reverse, background,className}) =>{
    return(
    <div className={`image-text bg-grey-100 flex m-wrap p20  ` + reverse  } style={{'background' : background}}> 
        <div className={"w-50 flex align-center m-100 mp0 m-mb0 m-b0 " + (reverse ? 'pl20 bl1' : 'pr20 br1')} style={{'background' : background}}>
            <div className={`ratio-2-1 bg-white pos-rel ${className}`}>  
                <GatsbyImage image={image} alt={alt ? alt : title } className="bg-image" /> 
            </div> 
        </div>
        <div className="w-50 m-100 pl20 mp0 flex align-center m-mb40">
            <div className="max-350 p20 ma m-mwa mp0">
                <p className="m0 h1 mb40 m-mt40">{title}</p>
                <div className="inner sainte mb20"> 
                {text}
                { second ? <div className="mt20">{second}</div> : null }
                </div>
                {caption ? <p className="m0 uppercase small">{caption}</p> : null}
                {(bottomIcon ? 
                    <div className='mt20 flex gap-20'>
                        <div className='award-icon small'><GatsbyImage image={bottomIcon?.gatsbyImageData} alt="Icon" className="bg-image contain" /></div>
                        <div className='award-icon small'><GatsbyImage image={bottomIcon?.gatsbyImageData} alt="Icon" className="bg-image contain" /></div>
                    </div>
                :'')}
                { button ? <Link to={link} className="button white mt20">{button}</Link> : null } 
            </div>
        </div>
    </div>
    )
}
export default ImageText